/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* src/index.css or src/App.css */
body {
  font-family: 'DM Sans';
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Sans', serif;
}

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
